import kakao from 'img/SignIn/kakao_login_large_narrow.png';

 
const SocialKakao = () => {
    const CLIENT_ID = "a98063ec13b1b46c83a36c3299f367ec";
    const REDIRECT_URI = "http://zakak.cafe24.com:8085/kakao/redirect";

    const KAKAO_AUTH_URL = `https://kauth.kakao.com/oauth/authorize?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=code`;
    const handleLogin = () => {
        window.location.href = KAKAO_AUTH_URL
    }
    return (
        <button className="login_img" onClick={handleLogin}>
            <img src={kakao}/>
        </button>
    )
}
export default SocialKakao