import 'pages/FirstPage/FirstPage.css';

import zakak from 'img/zakak_icon.svg';
import first_page from 'img/FirstPage/first_page.png';
import { useNavigate } from "react-router-dom";

import Introduce from 'Introduce'; // Introduce 컴포넌트 import


function FirstPage() {

  let navigate = useNavigate(); 
  const routeChange = () =>{ 
    let path = `/SignIn`; 
    navigate(path);
  }
  return (
    <div className="container">
      <div className="first_page_container" style={{ backgroundImage:  `url(${first_page})`  }}>
        <div className='first_page_item'>
          <img src={zakak} className="zakak_icon" alt="logo" />
          <p className='start_title'>Unreal 5 version 리얼타임 에디터</p>
          <button className="start-button" onClick={routeChange}>Get started</button>   

        </div>
      </div>
    </div>
  );
}



export default FirstPage;
