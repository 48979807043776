import 'App.css';
import back from 'img/back_arrow.svg';
import zakak from 'img/zakak_icon.svg';
import mypage from 'img/my_page_icon.svg';
import { useParams,useNavigate } from 'react-router-dom'; // useParams 불러오기


function Header(){
    let navigate = useNavigate(); 
    
    const goToMainPage = () => {
        navigate("/mainpage");
    }
    const goBack = () => {
        navigate(-1);
    };
    return (
        <div className="header">
          <img src={back} className="logo" alt="logo" onClick={goBack} style={{ height: '30px' }}/>
          <img src={zakak} className="logo" alt="logo" onClick={goToMainPage} style={{ height: '30px' }}/>
          <img src={mypage} className="logo" alt="logo"  style={{ height: '30px' }}/>
        </div>
    )
  }

  export default Header;