import back from 'img/back_arrow.svg';
import zakak from 'img/zakak_icon.svg';
import mypage from 'img/my_page_icon.svg';
import Header from 'Header'

import { useParams,useNavigate,useLocation } from 'react-router-dom'; // useParams 불러오기
import React, { useState, useEffect } from 'react';
import axios from 'axios';





import 'pages/Estimate/Estimate.css';

function Estimate() {
  const [productInfo, setProductInfo] = useState('');
  const [material, setMaterial] = useState('');
  const [fileType, setFileType] = useState('');
  const [presentation, setPresentation] = useState(false);
  const [noModeling, setNoModeling] = useState(false);
  const [specialRequest, setSpecialRequest] = useState(false);  

  let navigate = useNavigate();
  const GoToRequest = () => {
    navigate("/request");
  }

  const location = useLocation();
  const registerIdx = location.state.registerIdx;
  console.log(registerIdx);

  const [price,setData] = useState([]);
  useEffect(()=>{
    axios.get("http://zakak.cafe24.com:8085/api/price?registerIdx="+registerIdx,{
  }).then(function (res) {
        // 서버에서 받은 응답 처리
        console.log(res.data);
        setData(res.data);
    }).catch(function (error) {
        // 에러 처리
        console.log(error);
    });
  },[]);

  

  return (
    <div className="container">
      <Header/>
      <div className="container-estimate">
        <p>견적보기</p>
        <p>기본 모델링 작업이 적용 되었습니다.</p>

        <p>
          복잡한 모델링의 경우와<br/>
          특수한 텍스츄어가 필요한 경우<br/>
          비용이 추가 될 수 있습니다.
        </p>

        
        <div className="grid-container-estimate">
          <div><p className="grid-item-receipt-name">제품 모델링</p></div>
          <div className="grid-item-receipt-price-container"><p className="grid-item-receipt-price">{price.noModeling}</p>원</div>
          <div><p className="grid-item-receipt-name">연출 어셋ASET</p></div>
          <div className="grid-item-receipt-price-container"><p className="grid-item-receipt-price">{price.asset}</p>원</div>
          <div><p className="grid-item-receipt-name">가상공간 인입 및 촬영</p></div>
          <div className="grid-item-receipt-price-container"><p className="grid-item-receipt-price">{price.presentation}</p>원</div>
          <div><p className="grid-item-receipt-name">특수 텍스츄어</p></div>
          <div className="grid-item-receipt-price-container"><p className="grid-item-receipt-price">{price.specialRequest}</p>원</div>
        </div>
        <hr/>
        <div className="grid-container-estimate-result">
          <div><p className="grid-item-receipt-name">견적 결과</p></div>
          <div className="grid-item-receipt-price-container"><p className="grid-item-receipt-price-result">{price.total}</p>원</div>
        </div>
      </div>
      <p><button className="login-button" onClick={GoToRequest}>확인 및 견적보기</button></p>   
    </div>
  );
}

export default Estimate;