import React, { useState, useEffect } from 'react';

import back from 'img/back_arrow.svg';
import arrow_right from 'img/arrow-right.svg';
import zakak from 'img/zakak_icon.svg';
import mypage from 'img/my_page_icon.svg';

import Introduce from 'Introduce'; // Introduce 컴포넌트 import
import { useParams,useNavigate } from 'react-router-dom'; // useParams 불러오기
import axios from 'axios';


import Header from 'Header'



import './Room.css';

function Room() {
  const { id } = useParams();

  let navigate = useNavigate(); 
  const routeChange = () =>{ 
    let path = `/Register`; 
    navigate(path);
  }

  const [room, setRoom] = useState({}); // 초기값을 빈 객체로 설정
  useEffect(() => {
    axios.get("http://zakak.cafe24.com:8085/api/getRoom?room=" + id, {
      }).then(function (res) {
          // 서버에서 받은 응답 처리
          console.log(res.data);
          setRoom(res.data);
      }).catch(function (error) {
          // 에러 처리
          console.log(error);
      });
    
  }, [id]); // id가 변경될 때마다 fetch 호출

  return (
    <div className="container">
      <div className="room-background" style={{ backgroundImage:  `url(${"http://zakak.cafe24.com:8085/api/image?image=" + room.img + ".png"})`  }}>
        <Header></Header>
        <div className='room-content'>
          <p style={{ fontSize:"50px", fontWeight:"", marginBottom:"0"}}>{room.title}</p>
          <p>{room.content}</p>
          <p><button className="login-button" onClick={routeChange}>
            촬영예약/신청하기
            <img src={arrow_right}/>
            </button>
          </p>   
        </div>
      </div>
      <div>
        <div className="grid-container">
          <div className="grid-item-room">
            <img src={"http://zakak.cafe24.com:8085/api/image?image=" + room.img + "_1.png"} />
          </div>
          <div className="grid-item-room">
            <img src={"http://zakak.cafe24.com:8085/api/image?image=" + room.img + "_2.png"} />
          </div>
          <div className="grid-item-room">
            <img src={"http://zakak.cafe24.com:8085/api/image?image=" + room.img + "_3.png"} />
          </div>
          <div className="grid-item-room">
            <img src={"http://zakak.cafe24.com:8085/api/image?image=" + room.img + "_4.png"}  />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Room;