import { Routes, Route, BrowserRouter } from "react-router-dom";
import MainPage from "pages/MainPage/MainPage";
import SignIn from "pages/SignIn/SignIn";
import FirstPage from "pages/FirstPage/FirstPage";
import Room from "pages/Room/Room";
import Register from "pages/Register/Register";
import Estimate from "pages/Estimate/Estimate";
import RoomList from 'pages/Room/RoomList';
import Request from 'pages/Request/Request';
import Mypage from 'pages/Mypage/Mypage';
import Asset from 'pages/Asset/Asset';

import './App.css';
import './'
import { useEffect } from "react";

function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/mainpage" element={<MainPage />} />
        <Route path="/kakao/redirect" element={<MainPage />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/" element={<FirstPage />} />
        <Route path="/mainpage/:id" element={<Room />} />
        <Route path="/register" element={<Register />} />
        <Route path="/estimate" element={<Estimate />} />
        <Route path="/request" element={<Request />} />
        <Route path="/mypage" element={<Mypage />} />
        <Route path="/asset" element={<Asset />} />
      </Routes>
    </BrowserRouter> 
    
  );
}

export default App;


