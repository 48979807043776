import Introduce from 'Introduce'; // Introduce 컴포넌트 import
import { useNavigate } from "react-router-dom";

import SocialKakao from 'SocialKakao'

import './SingIn.css';
import infoIcon from 'img/info_icon.svg';
import kakao from 'img/SignIn/kakao_login_large_narrow.png';



function SingIn() {
  const gridData = [
    { id: 1, title: '1. 제품 자료 수집',content: '제품을 모든 각도에서 확인할 수 있는 사진과 각 부분의 정확한 크기 정보가 필요합니다.' },
    { id: 2, title: '2. 견적서 전달',content: '합의된 촬영 삼품 수와 컷수가 포함된 견적서를 전달하며, 필요한 문서나 정보가 있을 경우는 알려드립니다.' },
    { id: 3, title: '3. 실물 상품 3D화',content: '입금이 완료되면 수집된 자료를 바탕으로 스튜디오에서 활용될 3D 제품을 제작합니다.' },
    { id: 4, title: '4. 촬영준비',content: '제작한 3D 제품을 픽쳐스튜디오 촬영장에 옮긴 후 촬영을 위한 조명 및 카메라를 셋팅합니다.' },
    { id: 5, title: '5. 테스트촬영',content: '고객이 원하는 구도와 색감을 확인하기 위해 테스트 촬영을 진행합니다.' },
    { id: 6, title: '6. 최종 촬영 후 결과물 전달',content: '최종적으로 합의된 작업 방향과 컷 수에 따라 본 촬영을 진행합니다.' },
  ];

  let navigate = useNavigate(); 
  const routeChange = () =>{ 
    let path = `/MainPage`; 
    navigate(path);
  }

  return (
    <div className="container">
      <div className="context-container">
        재칵 픽쳐스튜디오는<br/>
        원활한 소통을 위해 별도의 회원가입 없이<br/>
        카카오톡으로 진행됩니다.
        <p style={{ marginTop: '40px' }}>카카오톡으로 로그인 해주세요.</p>
        <SocialKakao/>
        {/* <button className="login_img" onClick={routeChange}><img src={kakao}/></button> */}

      </div>

      <div className="info-container">
        <p><img src={infoIcon} className="logo" alt="logo"/>재칵의 PICTURE STUDIO 진행 안내</p>
        <div className="grid-container">
        {gridData.map(item => (
          <div key={item.id} className="grid-item">
            <p style={{ margin: '0px' }}>{item.title}</p>
            <p className='grid-item content'>{item.content}</p>
          </div>
        ))}
        </div>
      </div>
    </div>
  );
}

export default SingIn;
