import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import axios from 'axios';

import arrow from 'img/keyboard_arrow_down.svg';

// import roomList from './roomList.png';
import room_a from 'img/MainPage/room_a.png';
import room_b from 'img/MainPage/room_b.png';
import room_c from 'img/MainPage/room_c.png';
import room_d from 'img/MainPage/room_d.png';
import room_e from 'img/MainPage/room_e.png';
import Header from 'Header';

import './MainPage.css';

function RoomTag(props) {
  return (
    <div className="roomContainer" style={{ backgroundImage:  `url(${"http://zakak.cafe24.com:8085/api/image?image=" + props.img + "_main.png"})`  }}>
      <div className='room-context'>
        <p className="roomText"> 
          <p className="roomText roomA">{props.title}</p>
          {props.content}
        </p>
        <button className='main-button'>
          <Link to={`${props.img}`}>
            <img src={arrow} alt="Arrow icon" />
          </Link>
        </button>
      </div>
      
    </div>
  )
}



function MainPage() {

  const [rooms,setData] = useState([]);
  useEffect(()=>{
    axios.get("http://zakak.cafe24.com:8085/api/getRooms", {
      }).then(function (res) {
          // 서버에서 받은 응답 처리
          console.log(res.data);
          setData(res.data);
      }).catch(function (error) {
          // 에러 처리
          console.log(error);
      });
  },[]);

  return (

    <div className="container">
    <Header></Header>

      <div className="body">
        <div className="manual">
          {/* <img src={roomList} className="logo" alt="logo"></img> */}
          <p>오프라인 스튜디오에서 찍는 그대로 <br/>
            가상스튜디오에서의 촬영을 주문할 수 있습니다.</p>
        </div>
      </div>
      {rooms.map(item => (
        <RoomTag id={item.id} img={item.img} title={item.title} content={item.content} />
      ))}
    </div>
  );
}

export default MainPage;