import back from 'img/back_arrow.svg';
import zakak from 'img/zakak_icon.svg';
import item from 'img//Mypage/item.svg';
import mypage from 'img/my_page_icon.svg';
import Header from 'Header'
import { FileInput } from '../Register/Register'

import { useParams,useNavigate } from 'react-router-dom'; // useParams 불러오기

import 'pages/Mypage/Mypage.css';
import React, { useState } from 'react';


function Item() {
  return (
    <div className='item-container'>
      <img src={item}></img>
      <div className='item-container-div'>
        <p className='item-title'>가죽소파(roomA)</p>
        <p className='item-date'>2023-06-12</p>
      </div>
    </div>
  )
}

function Mypage() {
  let navigate = useNavigate(); 

  const GoToSubmit = () => {
    navigate("/submit");
  }

  const [selectedTime, setSelectedTime] = useState('');
  const [selectedDate, setSelectedDate] = useState('');


  // 시간이 변경될 때 실행되는 함수
  const handleTimeChange = (event) => {
    setSelectedTime(event.target.value);
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  return (
    <div className="container">
      <Header/>
      <div className="container-mypage">
        <p>마이페이지</p>
        <p>
          프로젝트를 선택하면 진행중인<br/>
          Picture Studio 사진을 보실 수 있습니다.<br/>
          보관된 사진은 보안관리 규정에 따라 4주 이후 삭제됩니다.<br/>
        </p>

        <div>
          <p>+ 견적 리스트</p>
          <div>
            <Item/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Mypage;