import Introduce from 'Introduce'; // Introduce 컴포넌트 import
import Header from 'Header'
import upload from 'img/Register/upload.svg';
import cart from 'img/Register/cart.svg';

import { useParams,useNavigate,useLocation } from 'react-router-dom'; // useParams 불러오기
import axios from 'axios';


import './Register.css';
import React, { useState } from 'react';

export const FileInput = ({ productFile,onFileChange} ) => {
  const handleFileChange = (event) => {
    // 파일 선택 시 호출되는 함수
    const file = event.target.files[0];
    onFileChange(file);
  };

  return (
    <div>
      <div style={{display:'flex'}}>
        <div className='register-button'>
          <label htmlFor="imageUpload" >
            <img src={upload} />
            <span style={{ textAlign: 'center' }}>파일 첨부</span>
          </label>
          <input id="imageUpload" type="file" accept=".jpg,.jpeg,.dwg" style={{ display: 'none' }} onChange={handleFileChange} />
          
        </div>
        jpg 또는 dwg 데이터를 첨부해주세요.
      </div>
      <p>
        {productFile && <p>{productFile.name}</p>}
      </p>
    </div>
  );
};

function Register() {

  
  const [productInfo, setProductInfo] = useState('');
  const [productFile, setProductFile] = useState(null);
  const [material, setMaterial] = useState('');
  const [fileType, setFileType] = useState('');
  const [presentation, setPresentation] = useState(false);
  const [noModeling, setNoModeling] = useState(false);
  const [specialRequest, setSpecialRequest] = useState(false);
  
  

  const formData = new FormData();
  const data = 
    {
      "userIdx":1,
      "editRequest":false,
      "confirm":false,
      "productInfo":productInfo,
      "material":material,
      "asset":"asset",
      "presentation":presentation,
      "fileType":fileType,
      "noModeling":noModeling,
      "specialRequest":specialRequest,
      "createAt": null,
      "update":null,
      "BR":"BR",
      "CB":"CB"
    }
  
  formData.append("file", productFile);
  formData.append("key", new Blob([JSON.stringify(data)],{type: 'application/json'}));

  let navigate = useNavigate(); 
  const GoToEstimate = (data) => {
    navigate('/estimate', {
      state: {registerIdx: `${data}`}
    });
  }

  const sendRegisterHandler = () => {
    axios.post("http://zakak.cafe24.com:8085/register/save", formData,{
      headers: {
          'Content-Type': `multipart/form-data`
      }
    }).then(function (res) {
        // 서버에서 받은 응답 처리
        console.log(res.data);
        GoToEstimate(res.data);
    }).catch(function (error) {
        // 에러 처리
        console.log(error);
    });
  };


  return (
    <div className="container">
      <Header></Header>
      <div>
        <p>예약신청</p>
        <div className="container-register">
          <div className="container-register-content">
            <p className="cotent-Q">제품명칭과 제품설명을 알려주세요</p>
            <input className='register-input' value={productInfo} onChange={(e) => setProductInfo(e.target.value)}></input>
          </div>
          <div className="container-register-content">
            <p className="cotent-Q">제품 모델링 파일과 제품 설명이 가능한 사진이 있다면 올려주세요.</p>
            <FileInput productFile={productFile} onFileChange={setProductFile}/>
          </div>
          <div className="container-register-content">
            <p className="cotent-Q">제품의 재료를 알려주세요. (부분별)</p>
            <input className='register-input' value={material} onChange={(e) => setMaterial(e.target.value)}></input>
          </div>
          <div className="container-register-content">
            <p className="cotent-Q">연출을 위해 필요한 어셋을 골라주세요.</p>
            <button className="register-button">
              <img src={cart}/>
              ASET 목록확인
            </button>
          </div>
          <div className="container-register-content">
            <p className="cotent-Q">제공하는 촬영컷을 확인하세요.</p>
            <p className="content-A"><input type="checkbox" className="register-input-checkbox" value={presentation} onChange={(e) => setPresentation(e.target.checked)}></input>연출 20컷, 누끼 5컷</p>
          </div>
          <div className="container-register-content">
            <p className="cotent-Q"> 제공받고자 하는 파일 형태를 알려주세요.</p>
            <input className='register-input' value={fileType} onChange={(e) => setFileType(e.target.value)}></input>       
          </div>
          <div className="container-register-content">
            <p className="cotent-Q">제품 모델링을 가지고 있지 않다면 체크해주세요.</p>
            <p className="content-A"><input type="checkbox"  className="register-input-checkbox" value={noModeling} onChange={(e) => setNoModeling(e.target.checked)}></input>모델링 유료제작</p>
          </div>
          <div className="container-register-content">
            <p className="cotent-Q">특별한 그래픽패턴, 대리석, 섬유패턴 등의 텐스츄어가 필요한 경우 체크해 주세요.</p>
            <p className="content-A"><input type="checkbox"  className="register-input-checkbox" value={specialRequest} onChange={(e) => setSpecialRequest(e.target.checked)}></input>모델링 유료제작</p>
          </div>
          <p>
            모델링 파일이 있다면 일주일 안에 촬영컷이 제공됩니다.<br/>
            모델링 파일이 없다면 난이도에 따라 기간과 단가가 달라 질 수 있습니다.
          </p>

          <p><button className="login-button" onClick={sendRegisterHandler}>확인 및 견적보기</button></p>   
          
        </div>
      </div>
    </div>
  );
}

export default Register;