import back from 'img/back_arrow.svg';
import zakak from 'img/zakak_icon.svg';
import mypage from 'img/my_page_icon.svg';
import Header from 'Header'
import { FileInput } from '../Register/Register'

import { useParams,useNavigate } from 'react-router-dom'; // useParams 불러오기



import 'pages/Request/Request.css';
import React, { useState } from 'react';


function Request() {
  let navigate = useNavigate(); 

  const GoToMyPage = () => {
    navigate("/myPage");
  }

  const [selectedTime, setSelectedTime] = useState('');
  const [selectedDate, setSelectedDate] = useState('');


  // 시간이 변경될 때 실행되는 함수
  const handleTimeChange = (event) => {
    setSelectedTime(event.target.value);
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  return (
    <div className="container">
      <Header/>
      <div className="container-estimate">
        <p>의뢰완료!</p>
        <p>
          아래 내용 작성 후 최종 의뢰 내용은<br/>
          마이페이지에서 확인 하실 수 있습니다.
        </p>

        <div className="container-register">
          <div className="container-register-content">
            <p className="cotent-Q">사업자등록증 사본과 환불을 위한 통장사본을 올려주세요.</p>
            <FileInput/>
          </div>
          
          <div className="container-register-content">
            <p className="cotent-Q">세금계산서 발행을 위한 이메일 주소를 알려주세요.</p>
            <input className='register-input'></input>
          </div>
          <div className="container-register-content">
            <p className="cotent-Q">상담 받으실 연락처를 알려주세요.</p>
            <input className='register-input'></input>    
          </div>
          <div className="container-register-content">
            <p className="cotent-Q">희망 상담일과 시간을 알려주세요.</p>
          </div>
          <div className="container-register-content">
            <p className="cotent-Q">날짜</p>
            <input type="date" value={selectedDate} onChange={handleDateChange} />   
          </div>
          <div className="container-register-content">
            <p className="cotent-Q">시간</p>
            <input type="time" value={selectedTime} onChange={handleTimeChange} />          
          </div>

          <p><button className="login-button" onClick={GoToMyPage}>확인</button></p>   
          
        </div>
      </div>
    </div>
  );
}

export default Request;